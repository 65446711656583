//酒店套餐api

import req from "../utils/req"

//携程促销列表
export function getCtripPromoteList_api(params) {
    return req.get("/ctrip/promote/getList", params)
}
//携程促销停用/启用
export function changeCtripPromoteState_api(params) {
    return req.get("/ctrip/promote/stopUp", params)
}
//获取房型和马甲
export function getPmRoomList_api(params) {
    return req.get("/ctrip/promote/getPmRoomList", params)
}

//创建携程促销
export function addPromotionNotify_api(params) {
    return req.postlg("/ctrip/promote/addPromotionNotify", params)
}

//getPromoteInfo_api
export function getPromoteInfo_api(params) {
    return req.get("/ctrip/promote/getPm", params)
}
//获取优享会列表

export function getMemberList_api(params) {
    return req.get("/ctrip/promote/getMemberList", params)
}

//加入、退出优享会
export function stopPromotionMemberUp_api(params) {
    return req.get("/ctrip/promote/stopPromotionMemberUp", params)
}
//新建参加优享会
export function addOptimalWill_api(params) {
    return req.postlg("/ctrip/promote/addPromotionMember", params)
}

//获取指定优享会信息
export function getPromotionMember_api(params) {
    return req.get("/ctrip/promote/getPromotionMember", params)
}

//获取艺龙模板列表
export function getPromotionTemplateList_api(params) {
    return req.get("/otaHotel/promotion-elong/getPromotionTemplateList", params)
}

//复制艺龙模板信息
export function copyPromotionTemplateInfo_api(params) {
    return req.postlg(`/otaHotel/promotion-elong/copyPromotionTemplateInfo/${params}` )
}

//绑定艺龙促销ID
export function bindPromotionId_api(params) {
    return req.postlg("/otaHotel/promotion-elong/bindPromotionId", params)
}

//艺龙促销列表
export function getYiLongPromoteList_api(params) {
    return req.get("/otaHotel/promotion-elong-rest/getPromotionList", params)
}


//艺龙促销停用/启用
export function changeYiLongPromoteState_api(params) {
    return req.get("/otaHotel/promotion-elong-rest/stopUp", params)
}

//艺龙获取房型和马甲
export function getPmRoomListYiLong_api(params) {
    return req.get("/otaHotel/promotion-elong-rest/getPmRoomList", params)
}
//获取艺龙促销详情getPromoteYoLongInfo_api



//新建/修改艺龙促销

export function addPromotionNotifyYiLong_api(params) {
    return req.postlg("/otaHotel/promotion-elong-rest/addPromotionNotify", params)
}

//获取艺龙促销信息
export function getYiLongPromoteInfo_api(params) {
    return req.get("/otaHotel/promotion-elong-rest/getPm", params)
}


//获取艺龙优享会列表
export function getMemberListYiLong_api(params) {
    return req.get("/otaHotel/promotion-elong-member/getMemberList", params)
}

//加入、退出艺龙优享会
export function stopPromotionMemberYiLongUp_api(params) {
    return req.get("/otaHotel/promotion-elong-member/stopPromotionMemberUp", params)
}
//新建参加艺龙优享会
export function addOptimalWillYiLong_api(params) {
    return req.postlg("/otaHotel/promotion-elong-member/addPromotionMember", params)
}
//获取优享会模板id
export function getMemberTemplateId_api(params) {
    return req.postlg("/otaHotel/promotion-elong/getMemberTemplateId", params)
}
//获取艺龙指定优享会信息
export function getPromotionMemberYiLong_api(params) {
    return req.get("/otaHotel/promotion-elong-member/getPromotionMember", params)
}

//删除携程促销
export function delCtripPromote_api(params) {
    return req.get("/ctrip/promote/delcx", params)
}

