<template>
  <div class="addPromotionbox">
    <div class="titlebox">
      <span class="marginleft10" @click="toPromotionManagement"
        ><i class="el-icon-arrow-left"></i>促销管理</span
      >
      <span> - {{ handData.name }}</span>
    </div>
    <div
      class="titleboxtwo"
      v-if="
        handData.code == '213' ||
        handData.code == '103' ||
        handData.code == '102'
      "
    >
      可订时间
    </div>
    <div
      class="mainbox"
      v-if="
        handData.code == '213' ||
        handData.code == '103' ||
        handData.code == '102'
      "
    >
      <div class="maintitle">售卖时间</div>
      <div class="margintop10">
        <el-date-picker
          size="mini"
          v-model="chooseDateOne"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="maintitle margintop20">适用时段</div>
      <div class="margintop10" v-if="handData.code == '213'">
        <el-checkbox v-model="checkedBookHour">24:00-次日06:00</el-checkbox>
      </div>
      <div class="margintop10" v-if="handData.code == '103'">
        <el-radio-group v-model="bookHourRadio">
          <el-radio
            :label="item.value"
            class="marginbottom20 marginright20"
            v-for="item in bookHourList"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="margintop10" v-if="handData.code == '102'">
        <el-select
          class="marginbottom20 marginright20 width90"
          size="mini"
          v-model="handData.bookHourStart"
        >
          <el-option
            v-for="item in bookHourListTwo"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span class="marginbottom20 marginright20">至</span>
        <el-select
          class="marginbottom20 marginright20 width90"
          size="mini"
          v-model="handData.bookHourEnd"
        >
          <el-option
            v-for="item in bookHourListTwo"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="titleboxtwo">促销条件</div>
    <div class="mainbox">
      <div class="maintitle">促销时间</div>
      <div
        class="margintop20"
        v-if="
          handData.code == '178' ||
          handData.code == '123' ||
          handData.code == '104'
        "
      >
        <el-radio
          v-if="handData.operateType == 'Update'"
          v-model="dateCount"
          @change="chooseDateCount"
          label="0"
          >不变</el-radio
        >
        <el-radio v-model="dateCount" @change="chooseDateCount" label="30"
          >30天</el-radio
        >
        <el-radio v-model="dateCount" @change="chooseDateCount" label="90"
          >90天</el-radio
        >
        <el-radio v-model="dateCount" @change="chooseDateCount" label="365"
          >365天</el-radio
        >
        <el-radio v-model="dateCount" @change="chooseDateCount" label="-1"
          >其它天数</el-radio
        >
        <!-- <el-input
          v-if="dateCount == '-1'"
          style="width: 100px; margin-left: 10px; margin-right: 10px"
          v-model="dateCount1"
          size="mini"
          placeholder="天数"
        ></el-input
        ><span v-if="discountCount == 10">天</span> -->
        <el-date-picker
          v-if="dateCount == '-1'"
          size="mini"
          v-model="dateCountDateLabel"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <div class="discountCountLabelBox">
          将在{{ dateCountDateLabel[0] }}至{{
            dateCountDateLabel[1]
          }}期间进行促销
        </div>
      </div>
      <div
        class="margintop20"
        v-if="
          handData.code == '213' ||
          handData.code == '103' ||
          handData.code == '102' ||
          handData.code == '107' ||
          handData.code == '106'
        "
      >
        <el-date-picker
          size="mini"
          v-model="chooseDateTwo"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div
        class="margintop20"
        v-if="
          handData.code == '213' ||
          handData.code == '103' ||
          handData.code == '123' ||
          handData.code == '104' ||
          handData.code == '102' ||
          handData.code == '107' ||
          handData.code == '106'
        "
      >
        <div class="inlineblockbox margintop3">适用星期</div>
        <div class="inlineblockbox">
          <el-checkbox
            style="margin-right: 30px; margin-left: 20px"
            :indeterminate="weekIndeterminate"
            v-model="ischeckAllWeek"
            @change="handleCheckAllWeekChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            v-model="checkedWeeks"
            style="display: inline-block"
            @change="handleCheckedWeeksChange"
          >
            <el-checkbox
              v-for="item in weeks"
              :label="item.id"
              :key="item.id"
              >{{ item.week }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div
        class="margintop20"
        v-if="
          handData.code == '213' ||
          handData.code == '103' ||
          handData.code == '123' ||
          handData.code == '104' ||
          handData.code == '102' ||
          handData.code == '107' ||
          handData.code == '106'
        "
      >
        <div class="inlineblockbox margintop5 marginright10">
          <el-checkbox v-model="notApplyDate">不适用日期</el-checkbox>
        </div>
        <div class="inlineblockbox" v-if="notApplyDate">
          <div
            v-for="(item, index) in notApplyDateList"
            :key="index"
            class="marginbottom10"
          >
            <el-date-picker
              size="mini"
              v-model="item.chooseDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div
              @click="addNotApplyDate(item, index)"
              class="inlineblockbox iconbox marginleft10 margintop3"
            >
              <i class="el-icon-plus"></i>
            </div>
            <div
              @click="delNotApplyDate(item, index)"
              v-if="notApplyDateList.length > 1"
              class="inlineblockbox iconbox marginleft10 margintop3"
            >
              <i class="el-icon-minus"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        class="maintitle margintop20"
        v-if="
          handData.code == '213' ||
          handData.code == '103' ||
          handData.code == '104' ||
          handData.code == '102' ||
          handData.code == '107'
        "
      >
        促销形式
      </div>
      <div
        class="margintop20"
        v-if="
          handData.code == '213' ||
          handData.code == '103' ||
          handData.code == '104' ||
          handData.code == '102' ||
          handData.code == '107' ||
          handData.code == '106'
        "
      >
        <el-radio v-model="promoteState" label="2">折扣</el-radio>
        <el-radio v-model="promoteState" label="1">立减</el-radio>
      </div>
      <div v-if="handData.code != '107' && handData.code != '106'">
        <div class="maintitle margintop20">设置优惠</div>
        <div class="margintop20" v-if="promoteState == 2">
          <!-- <el-radio v-model="discountCount" label="6">6折</el-radio>
          <el-radio v-model="discountCount" label="6.5">6.5折</el-radio>
          <el-radio v-model="discountCount" label="7">7折</el-radio>
          <el-radio v-model="discountCount" label="7.5">7.5折</el-radio>
          <el-radio v-model="discountCount" label="8">8折</el-radio>
          <el-radio v-model="discountCount" label="8.5">8.5折</el-radio>
          <el-radio v-model="discountCount" label="9">9折</el-radio>
          <el-radio v-model="discountCount" label="10">自定义折扣</el-radio> -->
          <el-radio
            v-model="discountCount"
            :label="item.value"
            v-for="item in discountCountList"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
          <el-input
            v-if="discountCount == 10"
            style="width: 100px; margin-left: 10px; margin-right: 10px"
            v-model="discountCount1"
            size="mini"
            placeholder="输入折扣"
          ></el-input
          ><span v-if="discountCount == 10">折</span>
        </div>
        <div class="margintop20" v-if="promoteState == 1">
          立减
          <el-input
            size="mini"
            style="width: 100px; margin-left: 10px; margin-right: 10px"
            v-model="knockCount"
          ></el-input
          >元
        </div>
      </div>
      <div v-if="handData.code == '107' || handData.code == '106'">
        <div class="maintitle margintop20">
          <div class="inlineblockbox selectbox" v-if="handData.code == '107'">
            连住天数
          </div>
          <div class="inlineblockbox selectbox" v-if="handData.code == '106'">
            提前预定天数
          </div>
          <div class="inlineblockbox">设置优惠</div>
        </div>
        <div
          class="margintop20"
          v-for="(item, index) in continuousDateList"
          :key="index"
        >
          <el-select
            v-if="handData.code == '107'"
            size="mini"
            class="selectbox"
            v-model="item.continuousDate"
          >
            <el-option
              v-for="item in continuousList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>

          <el-select
            v-if="handData.code == '106'"
            size="mini"
            class="selectbox"
            v-model="item.continuousDate"
          >
            <el-option
              v-for="item in advanceReserveList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>

          <el-select
            size="mini"
            class="selectbox"
            v-model="item.discountCount"
            v-if="promoteState == 2"
          >
            <el-option
              v-for="item in discountCountList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-if="item.discountCount == 10 && promoteState == 2"
            style="width: 100px; margin-left: 10px; margin-right: 10px"
            v-model="item.discountCount1"
            size="mini"
            placeholder="输入折扣"
          ></el-input
          ><span v-if="item.discountCount == 10 && promoteState == 2">折</span>

          <span v-if="promoteState == 1"> 立减</span>
          <el-input
            v-if="promoteState == 1"
            size="mini"
            style="width: 100px; margin-left: 10px; margin-right: 10px"
            v-model="item.knockCount"
          ></el-input
          ><span v-if="promoteState == 1"> 元 </span>

          <div
            @click="addContinuousDate(item, index)"
            class="inlineblockbox iconbox marginleft10 margintop3"
          >
            <i class="el-icon-plus"></i>
          </div>
          <div
            @click="delContinuousDate(item, index)"
            v-if="continuousDateList.length > 1"
            class="inlineblockbox iconbox marginleft10 margintop3"
          >
            <i class="el-icon-minus"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="titleboxtwo" v-if="handData.operateType == 'Create'">
      选择促销酒店
    </div>
    <div class="mainbox" v-if="handData.operateType == 'Create'">
      <div>
        <div class="checkOne">
          <span>城市</span>
          <el-input
            size="mini"
            v-model="searchData.city"
            style="width: 110px; margin-left: 10px; background-color: #f6f8fb"
            placeholder="城市名"
          ></el-input>
        </div>
        <div class="checkOne">
          <el-select size="mini" style="width: 100px" v-model="hotelNameorId">
            <el-option label="酒店名称" value="酒店名称"></el-option>
            <el-option label="酒店ID" value="酒店ID"></el-option>
          </el-select>

          <el-input
            v-show="hotelNameorId == '酒店名称'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.hotelName"
            placeholder="请输入酒店名称"
          >
          </el-input>
          <el-input
            v-show="hotelNameorId == '酒店ID'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.hotelName"
            placeholder="请输入酒店ID"
          >
          </el-input>
        </div>
        <div class="checkOne">
          <el-select size="mini" style="width: 100px" v-model="roomNameorId">
            <el-option label="房型名称" value="房型名称"></el-option>
            <el-option label="房型ID" value="房型ID"></el-option>
          </el-select>
          <el-input
            v-show="roomNameorId == '房型名称'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.roomName"
            placeholder="请输入房型名称"
          >
          </el-input>
          <el-input
            v-show="roomNameorId == '房型ID'"
            size="mini"
            style="width: 180px; margin-left: 10px"
            v-model="searchData.roomName"
            placeholder="请输入房型ID"
          >
          </el-input>
        </div>
        <el-button type="primary" @click="searchHotelTableFn" size="mini"
          >搜索</el-button
        >
        <div class="roomBoxOneOff" @click="setTableSwitch">
          {{ tableSwitch }}
        </div>
      </div>
      <div v-if="tableSwitch == '列表收起'" class="roomBoxOneHotelNameBox">
        <div
          class="roomBoxOneHotelName"
          :style="{ color: checkedColor == item.hotelId ? '#256DEF' : '' }"
          @click="searchHotelInfo(item.hotelId, index, item.hotelName)"
          v-for="(item, index) in hotelList"
          :key="item.hotelId"
        >
          <span style="margin-left: 10px">
            {{ item.hotelName | formatHotelName() }}
          </span>
        </div>
        <el-pagination
          v-if="total > 15"
          class="roomPagin"
          background
          small
          @current-change="handleCurrentChange"
          :page-size="15"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="titleboxtwo" style="color: #256def">{{ pmHotelName }}</div>
    <div class="mainbox">
      <div>
        <el-checkbox
          :disabled="handData.code == '178' || handData.code == '123'"
          :indeterminate="roomIndeterminate"
          v-model="ischeckAllRoom"
          @change="handleCheckAllRoomChange"
        >
          全选
        </el-checkbox>
      </div>
      <div v-for="(item, index) in pmRoomList" :key="index">
        <div class="marginbottom20 borderbottom">
          <div class="inlineblockbox" style="width: 200px">
            <el-checkbox
              :disabled="handData.code == '178' || handData.code == '123'"
              style="margin-right: 30px; margin-left: 20px"
              :indeterminate="item.indeterminate"
              v-model="item.checked"
              @change="handleCheckAllRoomItemChange(item, $event)"
            >
              {{ item.roomTypeName }}
            </el-checkbox>
          </div>
          <div class="inlineblockbox" style="width: 900px">
            <div
              class="inlineblockbox marginright20"
              v-for="ctem in item.list"
              :key="ctem.roomId"
            >
              <el-checkbox
                :disabled="handData.code == '178' || handData.code == '123'"
                @change="handleCheckRoomItemChange(ctem, $event)"
                v-model="ctem.checked"
                >{{ ctem.roomName }}</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="titleboxtwo inlineblockbox marginright10">关联修改</div>
      <div class="inlineblockbox margintop30">
        <div class="inlineblockbox marginright10" v-for="item in pmVestList" :key="item.hotelVestId">
          <el-checkbox
            @change="handleCheckVestChange(item, $event)"
            v-model="item.checked"
            >{{ item.horseName }}</el-checkbox
          >
        </div>
      </div>
    </div>
    <div class="titleboxtwo">
      <el-button type="primary" @click="addPromotionNotify">{{
        submitName
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { hotelList_api } from "../../apis/room";
import {
  getPmRoomList_api,
  addPromotionNotify_api,
  getPromoteInfo_api,
} from "../../apis/hotelPackage";
import local from "@/utils/local.js";
export default {
  data() {
    return {
      chooseDateOne: [],
      chooseDateTwo: [],
      checked: false,
      checkedBookHour: false,
      weeks: [
        { week: "周一", id: 1 },
        { week: "周二", id: 2 },
        { week: "周三", id: 3 },
        { week: "周四", id: 4 },
        { week: "周五", id: 5 },
        { week: "周六", id: 6 },
        { week: "周日", id: 0 },
      ],
      ischeckAllWeek: true,
      checkedWeeks: [1, 2, 3, 4, 5, 6, 0],
      weekIndeterminate: false,
      notApplyDate: false, //是否设置不适用日期
      notApplyDateList: [{ chooseDate: [new Date(), new Date()] }], //不适用日期列表
      promoteState: "2", //1立减2折扣
      discountCount: "6", //选择折扣
      discountCount1: "", //自定义折扣
      knockCount: "", //立减金额
      discountCountList: [
        { value: "6", label: "6折" },
        { value: "6.5", label: "6.5折" },
        { value: "7", label: "7折" },
        { value: "7.5", label: "7.5折" },
        { value: "8", label: "8折" },
        { value: "8.5", label: "8.5折" },
        { value: "9", label: "9折" },
        { value: "10", label: "自定义折扣" },
      ],
      continuousList: [
        { value: "2", label: "2天" },
        { value: "3", label: "3天" },
        { value: "4", label: "4天" },
        { value: "5", label: "5天" },
        { value: "6", label: "6天" },
        { value: "7", label: "7天" },
        { value: "8", label: "8天" },
        { value: "9", label: "9天" },
        { value: "10", label: "10天" },
      ],
      advanceReserveList: [
        { value: "1", label: "1天" },
        { value: "2", label: "2天" },
        { value: "3", label: "3天" },
        { value: "4", label: "4天" },
        { value: "5", label: "5天" },
        { value: "6", label: "6天" },
        { value: "7", label: "7天" },
        { value: "8", label: "8天" },
        { value: "9", label: "9天" },
        { value: "10", label: "10天" },
        { value: "11", label: "11天" },
        { value: "12", label: "12天" },
        { value: "13", label: "13天" },
        { value: "14", label: "14天" },
        { value: "15", label: "15天" },
      ],
      continuousDateList: [
        {
          continuousDate: "",
          discountCount: "",
          discountCount1: "",
          knockCount: "",
        },
      ],
      searchData: {
        city: "",
        hotelName: "",
        hotelId: "",
        roomName: "",
        roomId: "",
        page: 1,
        rows: "15",
      },
      hotelNameorId: "酒店名称",
      roomNameorId: "房型ID",
      hotelList: [],
      total: 0,
      tableSwitch: "列表收起",
      checkedColor: 0,
      pmRoomList: [],
      pmVestList: [],
      pmHotelName: "",
      roomIndeterminate: false,
      ischeckAllRoom: false,
      handData: {
        //创建午夜特惠的数据
        hotelSonId: "",
        hotelVestIds: "",
        roomIds: "",
        name: "午夜特惠",
        code: "213",
        operateType: "Create",
        bookDateStart: "",
        bookDateEnd: "",
        bookHourStart: "",
        bookHourEnd: "",

        details: [
          {
            promotionMethod: "1",
            activeWeekdays: "1111111",
            start: "",
            end: "",
            priceValue: "",
            notTime: [["2022-03-25", "2022-03-25"]],
          },
        ],
      },
      bookHourList: [
        { value: 16, label: "16:00-次日06:00" },
        { value: 17, label: "17:00-次日06:00" },
        { value: 18, label: "18:00-次日06:00" },
        { value: 19, label: "19:00-次日06:00" },
        { value: 20, label: "20:00-次日06:00" },
        { value: 21, label: "21:00-次日06:00" },
        { value: 22, label: "22:00-次日06:00" },
        { value: 23, label: "23:00-次日06:00" },
        { value: 24, label: "24:00-次日06:00" },
        { value: 25, label: "次日01:00-次日06:00" },
        { value: 26, label: "次日02:00-次日06:00" },
        { value: 27, label: "次日03:00-次日06:00" },
        { value: 28, label: "次日04:00-次日06:00" },
        { value: 29, label: "次日05:00-次日06:00" },
      ],
      bookHourListTwo: [
        { value: "07", label: "07" },
        { value: "08", label: "08" },
        { value: "09", label: "09" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
      ],
      bookHourRadio: 16,
      dateCount: "30",
      dateCount1: "",
      pid: "",
      dateCountDateLabel: [new Date(), new Date()],
      submitName: "创建",
    };
  },
  created() {
    if (this.$route.query.promoteName) {
      this.handData.name = this.$route.query.promoteName;
      this.handData.code = this.$route.query.promoteCode;
      this.dateCountDateLabel[0] = this.formatDate(new Date());
      this.dateCountDateLabel[1] = this.formatDate(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      );
       this.searchHotelTableFn();
    } else {
      this.pid = this.$route.query.pid;
      this.submitName = "保存";
      this.getPromoteInfo();
    }
   
  },
  filters: {
    formatHotelName(val) {
      if (val.length < 20) {
        return val;
      } else {
        let newstr = val.substring(0, 16);
        return newstr + "...";
      }
    },
  },
  methods: {
    //回到促销管理
    toPromotionManagement() {
      this.$router.push({
        path: "/promotionManagement",
      });
    },
    // 周的全选
    handleCheckAllWeekChange(val) {
      let weekIds = [];
      this.weeks.forEach((item) => {
        weekIds.push(item.id);
      });
      this.checkedWeeks = val ? weekIds : [];
      this.weekIndeterminate = false;
    },
    // 周的单选
    handleCheckedWeeksChange(value) {
      let checkedCount = value.length;
      this.ischeckAllWeek = checkedCount === this.weeks.length;
      this.weekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },
    //增加不适用日期
    addNotApplyDate(item, index) {
      this.notApplyDateList.splice(index, 0, item);
      this.notApplyDateList = JSON.parse(JSON.stringify(this.notApplyDateList));
    },
    //减少不适用日期
    delNotApplyDate(item, index) {
      this.notApplyDateList.splice(index, 1);
      this.notApplyDateList = JSON.parse(JSON.stringify(this.notApplyDateList));
    },
    //增加连住时间段
    addContinuousDate(item, index) {
      this.continuousDateList.splice(index, 0, item);
      this.continuousDateList = JSON.parse(
        JSON.stringify(this.continuousDateList)
      );
    },
    //删除连住时间段
    delContinuousDate(item, index) {
      this.continuousDateList.splice(index, 1);
      this.continuousDateList = JSON.parse(
        JSON.stringify(this.continuousDateList)
      );
    },

    searchHotelTableFn() {
      local.remove("roomPageHotelId");
      this.searchData.page = 1;
      this.searchHotelTable();
    },
    //根据条件查询酒店列表
    async searchHotelTable() {
      let { code, data } = await hotelList_api(this.searchData);
      if (code == 0) {
        this.hotelList = data.records;
        this.total = data.total;
        if (local.get("roomPageHotelId")) {
          this.getHotelInfo(local.get("roomPageHotelId"));
        } else {
          if (this.hotelList.length > 0) {
            this.searchHotelInfo(
              this.hotelList[0].hotelId,
              0,
              this.hotelList[0].hotelName
            );
          }
        }
      }
    },
    //酒店列表分页
    handleCurrentChange(val) {
      // console.log(val);
      this.searchData.page = val;
      this.searchHotelTable();
    },
    //列表开关
    setTableSwitch() {
      if (this.tableSwitch == "列表收起") {
        this.tableSwitch = "列表展开";
      } else {
        this.tableSwitch = "列表收起";
      }
    },
    searchHotelInfo(id, index, hotelName) {
      local.set("roomPageHotelId", id);
      this.checkedColor = id;
      this.pmHotelName = hotelName;
      this.getHotelInfo(id);
    },
    async getHotelInfo(id) {
      let { code, data } = await getPmRoomList_api({
        hotelSonId: id,
      });
      if (code == 0) {
        this.handData.hotelSonId = id;

        if (this.handData.code == "178" || this.handData.code == "123") {
          data.list.forEach((item) => {
            this.$set(item, "checked", true);
            this.$set(item, "indeterminate", false);
            item.list.forEach((ctem) => {
              this.$set(ctem, "checked", true);
            });
          });
          this.roomIndeterminate = false;
          this.ischeckAllRoom = true;
        } else {
          let roomCount = 0;
          data.list.forEach((item) => {
            let count = 0;
            item.list.forEach((ctem) => {
              if (ctem.isCk == 0) {
                this.$set(ctem, "checked", false);
              } else if (ctem.isCk == 1) {
                count++;
                this.$set(ctem, "checked", true);
              }
            });
            if (count == 0) {
              this.$set(item, "checked", false);
              this.$set(item, "indeterminate", false);
            } else if ((count = item.list.length)) {
              this.$set(item, "checked", true);
              this.$set(item, "indeterminate", false);
              roomCount++;
            } else {
              this.$set(item, "checked", false);
              this.$set(item, "indeterminate", true);
            }
          });
          if (roomCount == 0) {
            this.roomIndeterminate = false;
            this.ischeckAllRoom = false;
          } else if (roomCount == data.list.length) {
            this.roomIndeterminate = false;
            this.ischeckAllRoom = true;
          } else {
            this.roomIndeterminate = true;
            this.ischeckAllRoom = false;
          }
        }

        data.vest.forEach((item) => {
          if (item.isCk == 0) {
            this.$set(item, "checked", false);
          } else if (item.isCk == 1) {
            //  count++;
            this.$set(item, "checked", true);
          }
        });

        this.pmRoomList = data.list;
        this.pmVestList = data.vest;
      }
    },
    //所有房型全选
    handleCheckAllRoomChange(e) {
      let that = this;
      that.roomIndeterminate = false;
      that.ischeckAllRoom = e;
      that.pmRoomList.forEach((item) => {
        item.checked = e;
        item.indeterminate = false;
        item.list.forEach((ctem) => {
          ctem.checked = e;
        });
      });
    },
    //售卖房型全选，也就是物理房型单选
    handleCheckAllRoomItemChange(item, e) {
      item.indeterminate = false;
      item.checked = e;
      item.list.forEach((ctem) => {
        ctem.checked = e;
      });

      this.getIsCheckAll();
    },
    //售卖房型单选
    handleCheckRoomItemChange(ctem, e) {
      ctem.checked = e;
      this.getIsCheckAll();
    },
    //x选择引起的上层全选框变动
    getIsCheckAll() {
      let outCount = 0;
      this.pmRoomList.forEach((item) => {
        let count = 0;
        item.list.forEach((ctem) => {
          if (ctem.checked == true) {
            count++;
          }
        });
        if (count == 0) {
          item.checked = false;
          item.indeterminate = false;
        } else if (count == item.list.length) {
          item.checked = true;
          item.indeterminate = false;
        } else {
          item.checked = false;
          item.indeterminate = true;
        }
        if (item.checked == true) {
          outCount++;
        }
      });
      if (outCount == 0) {
        this.roomIndeterminate = false;
        this.ischeckAllRoom = false;
      } else if (outCount == this.pmRoomList.length) {
        this.roomIndeterminate = false;
        this.ischeckAllRoom = true;
      } else {
        this.roomIndeterminate = true;
        this.ischeckAllRoom = false;
      }
    },
    //关联修改
    handleCheckVestChange(item, e) {
      item.checked = e;
    },
    addPromotionNotify() {
      //售卖时间
      if (this.chooseDateOne.length > 0) {
        this.handData.bookDateStart = this.chooseDateOne[0];
        this.handData.bookDateEnd = this.chooseDateOne[1];
      } else {
        this.handData.bookDateStart = "";
        this.handData.bookDateEnd = "";
      }
      //售卖时段
      if (this.handData.code == "213") {
        if (this.checkedBookHour) {
          this.handData.bookHourStart = "24";
          this.handData.bookHourEnd = "30";
        } else {
          this.handData.bookHourStart = "";
          this.handData.bookHourEnd = "";
        }
      } else if (this.handData.code == "103") {
        this.handData.bookHourEnd = "30";
        this.handData.bookHourStart = this.bookHourRadio;
      }

      //促销时间
      if (
        this.handData.code == "213" ||
        this.handData.code == "103" ||
        this.handData.code == "102" ||
        this.handData.code == "107" ||
        this.handData.code == "106"
      ) {
        if (this.chooseDateTwo.length > 0) {
          this.handData.details[0].start = this.chooseDateTwo[0];
          this.handData.details[0].end = this.chooseDateTwo[1];
        }
      } else if (
        this.handData.code == "178" ||
        this.handData.code == "123" ||
        this.handData.code == "104"
      ) {
        if (this.dateCount != 0) {
          this.handData.details[0].start = this.formatDate(new Date());
          let upDateCount;
          if (this.dateCount == "-1") {
            // upDateCount = Number(this.dateCount1);
            // this.handData.details[0].end = this.formatDate(
            //   new Date().getTime() + upDateCount * 24 * 60 * 60 * 1000
            // );
            this.handData.details[0].start = this.dateCountDateLabel[0];
            this.handData.details[0].end = this.dateCountDateLabel[1];
          } else {
            this.handData.details[0].end = this.formatDate(
              new Date().getTime() +
                Number(this.dateCount) * 24 * 60 * 60 * 1000
            );
          }
        }
      }
      //不适用时间段
      if (this.notApplyDate) {
        this.handData.details[0].notTime = [];
        this.notApplyDateList.forEach((item) => {
          this.handData.details[0].notTime.push(item.chooseDate);
        });
      } else {
        this.handData.details[0].notTime = [];
      }
      //不适用星期
      let count1;
      let count2;
      let count3;
      let count4;
      let count5;
      let count6;
      let count7;
      if (this.checkedWeeks.indexOf(1) == -1) {
        count1 = "0";
      } else {
        count1 = "1";
      }
      if (this.checkedWeeks.indexOf(2) == -1) {
        count2 = "0";
      } else {
        count2 = "1";
      }
      if (this.checkedWeeks.indexOf(3) == -1) {
        count3 = "0";
      } else {
        count3 = "1";
      }
      if (this.checkedWeeks.indexOf(4) == -1) {
        count4 = "0";
      } else {
        count4 = "1";
      }
      if (this.checkedWeeks.indexOf(5) == -1) {
        count5 = "0";
      } else {
        count5 = "1";
      }
      if (this.checkedWeeks.indexOf(6) == -1) {
        count6 = "0";
      } else {
        count6 = "1";
      }
      if (this.checkedWeeks.indexOf(0) == -1) {
        count7 = "0";
      } else {
        count7 = "1";
      }

      this.handData.details[0].activeWeekdays =
        count1 + count2 + count3 + count4 + count5 + count6 + count7;
      //促销房型
      let roomIdsList = [];
      this.pmRoomList.forEach((item) => {
        item.list.forEach((ctem) => {
          if (ctem.checked == true) {
            roomIdsList.push(ctem.roomId);
          }
        });
      });
      //促销渠道  关联修改
      let vestIdsList = [];
      this.pmVestList.forEach((item) => {
        if (item.checked == true) {
          vestIdsList.push(item.hotelVestId);
        }
      });

      //促销形式，2为折扣，1为立减

      this.handData.details[0].promotionMethod = this.promoteState;

      if (this.handData.code != "107" && this.handData.code != "106") {
        if (this.promoteState == 2) {
          if (this.discountCount == 10) {
            this.handData.details[0].priceValue = this.discountCount1;
          } else {
            this.handData.details[0].priceValue = this.discountCount;
          }
        } else if (this.promoteState == 1) {
          this.handData.details[0].priceValue = this.knockCount;
        }
      } else if (this.handData.code == "107" || this.handData.code == "106") {
        for (let i = 0; i < this.continuousDateList.length; i++) {
          if (i > 0) {
            this.handData.details.push(
              JSON.parse(JSON.stringify(this.handData.details[0]))
            );
          }
        }
        for (let i = 0; i < this.continuousDateList.length; i++) {
          if (this.handData.code == "107") {
            this.$set(
              this.handData.details[i],
              "continuousDays",
              this.continuousDateList[i].continuousDate
            );
          } else if (this.handData.code == "106") {
            this.$set(
              this.handData.details[i],
              "predeterminedDays",
              this.continuousDateList[i].continuousDate
            );
          }

          if (this.promoteState == 2) {
            if (this.continuousDateList[i].discountCount == 10) {
              this.handData.details[i].priceValue =
                this.continuousDateList[i].discountCount1;
            } else {
              this.handData.details[i].priceValue =
                this.continuousDateList[i].discountCount;
            }
          } else if (this.promoteState == 1) {
            this.handData.details[i].priceValue =
              this.continuousDateList[i].knockCount;
          }
        }
      }
      // else if (this.handData.code == "106") {
      // }

      if (!this.handData.hotelSonId || roomIdsList.length == 0) {
        this.$message.error("请选择促销房型");
      } else {
        this.handData.roomIds = roomIdsList.join(",");
        if (vestIdsList.length == 0) {
          this.$message.error("请选择促销渠道");
        } else {
          this.handData.hotelVestIds = vestIdsList.join(",");
          if (!this.handData.details[0].end) {
            this.$message.error("请选择促销时间");
          } else {
            this.addPromotionNotifyFn();
          }
        }
      }
    },

    async addPromotionNotifyFn() {
      let { code, data } = await addPromotionNotify_api(this.handData);
      if (code == 0) {
        this.$message.success(
          `成功${this.submitName}${this.handData.name}促销`
        );
      }
    },
    chooseDateCount(val) {
      if (val == 0) {
        this.dateCountDateLabel = [];
        this.dateCountDateLabel.push(this.handData.details[0].start);
        this.dateCountDateLabel.push(this.handData.details[0].end);
      } else if (val == "-1") {
        // this.dateCountDateLabel.push(this.formatDate(new Date())) ;
        // this.dateCountDateLabel.push(this.formatDate(
        //   new Date().getTime() + Number(this.dateCount1) * 24 * 60 * 60 * 1000
        // )) ;
      } else {
        this.dateCountDateLabel = [];
        this.dateCountDateLabel.push(this.formatDate(new Date()));
        this.dateCountDateLabel.push(
          this.formatDate(
            new Date().getTime() + Number(val) * 24 * 60 * 60 * 1000
          )
        );
      }
    },
    formatDate(date) {
      let myDate = new Date(date);
      let y = myDate.getFullYear();
      let m = myDate.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }
      let d = myDate.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      return y + "-" + m + "-" + d;
    },

    async getPromoteInfo() {
      let { code, data } = await getPromoteInfo_api({
        pid: this.pid,
      });
      if (code == 0) {
        this.handData = data;
        //修改字段
        this.handData.operateType = "Update";
        //售卖时间
        this.chooseDateOne = [];
        this.pmHotelName = this.handData.hotelName;
        // this.chooseDateOne[0] = this.handData.bookDateStart.substring(0, 10);
        // this.chooseDateOne[1] = this.handData.bookDateEnd.substring(0, 10);
        this.chooseDateOne.push(this.handData.bookDateStart.substring(0, 10));
        this.chooseDateOne.push(this.handData.bookDateEnd.substring(0, 10));

        //售卖时段
        if (this.handData.code == "213") {
          if (
            this.handData.bookHourStart == "24" &&
            this.handData.bookHourEnd == "30"
          ) {
            this.checkedBookHour = true;
          } else {
            this.checkedBookHour = false;
          }
        } else if (this.handData.code == "103") {
          this.bookHourRadio = this.handData.bookHourStart;
        }

        //促销时间
        if (
          this.handData.code == "213" ||
          this.handData.code == "103" ||
          this.handData.code == "102" ||
          this.handData.code == "107" ||
          this.handData.code == "106"
        ) {
          this.chooseDateTwo = [];
          this.chooseDateTwo.push(
            this.handData.details[0].start.substring(0, 10)
          );
          this.chooseDateTwo.push(
            this.handData.details[0].end.substring(0, 10)
          );
          // this.chooseDateTwo[1] = this.handData.details[0].end.substring(0, 10);
        } else if (
          this.handData.code == "178" ||
          this.handData.code == "123" ||
          this.handData.code == "104"
        ) {
          this.dateCount = "0";
          this.dateCountDateLabel[0] = this.handData.details[0].start.substring(
            0,
            10
          );
          this.dateCountDateLabel[1] = this.handData.details[0].end.substring(
            0,
            10
          );
        }

        //不适用时间段
        if (
          this.handData.details[0].notTime &&
          this.handData.details[0].notTime.length > 0
        ) {
          this.notApplyDate = true;
          this.notApplyDateList = [];
          this.handData.details[0].notTime.forEach((item) => {
            this.notApplyDateList.push({ chooseDate: item });
          });
        }

        //不适用星期
        this.checkedWeeks = [];
        let weekCount = 0;
        if (this.handData.details[0].activeWeekdays.charAt(0) == 1) {
          this.checkedWeeks.push(1);
          weekCount++;
        }
        if (this.handData.details[0].activeWeekdays.charAt(1) == 1) {
          this.checkedWeeks.push(2);
          weekCount++;
        }
        if (this.handData.details[0].activeWeekdays.charAt(2) == 1) {
          this.checkedWeeks.push(3);
          weekCount++;
        }
        if (this.handData.details[0].activeWeekdays.charAt(3) == 1) {
          this.checkedWeeks.push(4);
          weekCount++;
        }
        if (this.handData.details[0].activeWeekdays.charAt(4) == 1) {
          this.checkedWeeks.push(5);
          weekCount++;
        }
        if (this.handData.details[0].activeWeekdays.charAt(5) == 1) {
          this.checkedWeeks.push(6);
          weekCount++;
        }
        if (this.handData.details[0].activeWeekdays.charAt(6) == 1) {
          this.checkedWeeks.push(0);
          weekCount++;
        }

        if (weekCount == 0) {
          this.ischeckAllWeek = false;
          this.weekIndeterminate = false;
        } else if (weekCount == 7) {
          this.ischeckAllWeek = true;
          this.weekIndeterminate = false;
        } else {
          this.ischeckAllWeek = false;
          this.weekIndeterminate = true;
        }

        let roomCount = 0;
        data.prm.list.forEach((item) => {
          let count = 0;
          item.list.forEach((ctem) => {
            if (ctem.isCk == 0) {
              this.$set(ctem, "checked", false);
            } else if (ctem.isCk == 1) {
              count++;
              this.$set(ctem, "checked", true);
            }
          });
          if (count == 0) {
            this.$set(item, "checked", false);
            this.$set(item, "indeterminate", false);
          } else if ((count = item.list.length)) {
            this.$set(item, "checked", true);
            this.$set(item, "indeterminate", false);
            roomCount++;
          } else {
            this.$set(item, "checked", false);
            this.$set(item, "indeterminate", true);
          }
        });
        if (roomCount == 0) {
          this.roomIndeterminate = false;
          this.ischeckAllRoom = false;
        } else if (roomCount == data.prm.list.length) {
          this.roomIndeterminate = false;
          this.ischeckAllRoom = true;
        } else {
          this.roomIndeterminate = true;
          this.ischeckAllRoom = false;
        }
        data.prm.vest.forEach((item) => {
          if (item.isCk == 0) {
            this.$set(item, "checked", false);
          } else if (item.isCk == 1) {
            // count++;
            this.$set(item, "checked", true);
          }
        });

        this.pmRoomList = data.prm.list;
        this.pmVestList = data.prm.vest;

        //促销形式，2为折扣，1为立减
        this.promoteState = this.handData.details[0].promotionMethod;
        if (this.handData.code != "107" && this.handData.code != "106") {
          if (this.promoteState == 2) {
            if (
              this.handData.details[0].priceValue == "6" ||
              this.handData.details[0].priceValue == "6.5" ||
              this.handData.details[0].priceValue == "7" ||
              this.handData.details[0].priceValue == "7.5" ||
              this.handData.details[0].priceValue == "8" ||
              this.handData.details[0].priceValue == "8.5" ||
              this.handData.details[0].priceValue == "9"
            ) {
              this.discountCount = this.handData.details[0].priceValue;
            } else {
              this.discountCount = "10";
              this.discountCount1 = this.handData.details[0].priceValue;
            }
          } else if (this.promoteState == 1) {
            this.knockCount = this.handData.details[0].priceValue;
          }
        } else if (this.handData.code == "107" || this.handData.code == "106") {
          for (let i = 0; i < this.handData.details.length; i++) {
            if (i > 0) {
              this.continuousDateList.push(
                JSON.parse(JSON.stringify(this.continuousDateList[0]))
              );
            }
          }

          for (let i = 0; i < this.handData.details.length; i++) {
            if (this.handData.code == "107") {
              this.$set(
                this.continuousDateList[i],
                "continuousDate",
                this.handData.details[i].continuousDays
              );
            } else if (this.handData.code == "106") {
              this.$set(
                this.continuousDateList[i],
                "continuousDate",
                this.handData.details[i].predeterminedDays
              );
            }

            if (this.promoteState == 2) {
              if (
                this.handData.details[i].priceValue == "6" ||
                this.handData.details[i].priceValue == "6.5" ||
                this.handData.details[i].priceValue == "7" ||
                this.handData.details[i].priceValue == "7.5" ||
                this.handData.details[i].priceValue == "8" ||
                this.handData.details[i].priceValue == "8.5" ||
                this.handData.details[i].priceValue == "9"
              ) {
                this.continuousDateList[i].discountCount =
                  this.handData.details[i].priceValue;
              } else {
                this.continuousDateList[i].discountCount = "10";
                this.continuousDateList[i].discountCount1 =
                  this.handData.details[i].priceValue;
              }
            } else if (this.promoteState == 1) {
              this.continuousDateList[i].knockCount =
                this.handData.details[i].priceValue;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.addPromotionbox {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 20px;
}
.titlebox {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #256def;
  border-radius: 10px 10px 0px 0px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fafcff;
}
.marginleft10 {
  margin-left: 10px;
  cursor: pointer;
}
.titleboxtwo {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.mainbox {
  margin: 0 auto;
  width: 1120px;
  padding: 20px;
  background: #f1f5fb;
  border-radius: 10px;
  position: relative;
}
.maintitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}
.margintop10 {
  margin-top: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.inlineblockbox {
  display: inline-block;
  vertical-align: top;
}
.iconbox {
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  background-color: #256def;
  border-radius: 50%;
  color: #ffffff;
}
.margintop5 {
  margin-top: 5px;
}
.margintop3 {
  margin-top: 3px;
}
.marginright10 {
  margin-right: 10px;
}
.marginbottom10 {
  margin-bottom: 10px;
}
.checkOne {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 20px;
}
.roomBoxOneOff {
  width: 56px;
  height: 28px;
  line-height: 28px;
  color: #256ef1;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  //   display: inline-block;
  float: right;
}
.roomBoxOneHotelNameBox {
  width: 100%;
  background-color: #f1f5fb;
}
.roomBoxOneHotelName {
  display: inline-block;
  cursor: pointer;
  width: 20%;
  margin: 10px auto;
  //text-align: center;
}
.roomBoxOneHotelName:hover {
  color: #256def;
}
.roomPagin {
  text-align: right;
}
.margintop30 {
  margin-top: 30px;
}
.marginright20 {
  margin-right: 20px;
}
.marginbottom20 {
  margin-bottom: 20px;
}
.borderbottom {
  border-bottom: 1px solid #dee4ed;
  padding-bottom: 10px;
}
.width90 {
  width: 148px;
}
.selectbox {
  width: 180px;
  margin-right: 20px;
}
.discountCountLabelBox {
  margin-top: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
</style>>

