//房型管理api

import req from "../utils/req"

//筛选酒店接口
export function addRoomType_api(params) {
     return req.postlg("/otaHotel/room-type/addorUpRoomType", params)
}
//获取携程马甲
export function getCtripVest_api(param) {
     return req.get("/otaHotel/room-type/getCtripVest", param)
}
//搜索获取酒店列表
export function hotelList_api(param) {
     return req.get("/otaHotel/room-type/getHotelSonListInfoByConditionSearch", param)
}
//根据酒店id查询酒店信息
export function hotelInfo_api(param) {
     return req.get("/otaHotel/room-type/getHotelInfoById", param)
}
//根据酒店id查询酒店所有渠道马甲
export function platformVo_api(param) {
     return req.get("/otaHotel/room-type/getPlatformVo", param)
}

//根据酒店id，马甲id，平台id查询房态管理
export function roomInfoById_api(param) {
     return req.get("/otaHotel/room-type/getRoomVestList", param)
}


//根据飞猪酒店id获取第三方物理房型列表
export function maRoomTypeForFliggy_api(param) {
     return req.get("/otaHotel/room-type/maRoomType", param)
}
//匹配飞猪物理房型
export function matchingFlyPigRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingFlyPigRoomType", param)
}


//根据艺龙酒店id获取第三方物理房型列表
export function elongRoomType_api(param) {
     return req.get("/otaHotel/room-type/getElongRoomType", param)
}
//匹配艺龙物理房型
export function matchingElongRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingElongRoomType", param)
}

//根据萝卜酒店id获取第三方物理房型列表
export function luoboRoomType_api(param) {
     return req.get("/otaHotel/room-type/getLuoboRoomType", param)
}
//匹配萝卜物理房型
export function matchingLuoboRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingLuoboRoomType", param)
}

//根据天下房仓酒店id获取第三方物理房型列表
export function fctxRoomType_api(param) {
     return req.get("/otaHotel/room-type/getFctxRoomType", param)
}
//匹配天下房仓物理房型
export function matchingFctxRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingFctxRoomType", param)
}



//根据携程酒店id获取第三方物理房型列表
export function ctripRoomType_api(param) {
     return req.get("/otaHotel/room-type/getCtripRoomType", param)
}
//匹配携程物理房型
export function matchingCtripRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingCtripRoomType", param)
}



//匹配去哪儿物理房型
export function matchingQunarRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingQunarRoomType", param)
}
//匹配美团物理房型
export function matchingMeituanRoomType_api(param) {
     return req.get("/otaHotel/room-type/matchingMtRoomType", param)
}
//添加售卖房型
export function addorUpRoom_api(param) {
     return req.postlg("/otaHotel/room/addorUpRoom", param)
}
// 获取售卖房型静态
export function roomVest_api(param) {
     return req.get("/otaHotel/room/getRoomVest", param)
}
//单个售卖房型json 批量修改艺龙售卖房型(包括单日开关)
export function upYiLongRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upYiLongRp", param)
}
//单个售卖房型json 批量修改飞猪售卖房型(包括单日开关)
export function upFlyPigRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upFlyPigRp", param)
}
//单个售卖房型json 批量修改美团售卖房型(包括单日开关)
export function upMeiTuanRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upMeiTuanRp", param)
}
//单个售卖房型json 批量修改去哪儿售卖房型(包括单日开关)
export function upQunarRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upQunarRp", param)
}
//单个售卖房型json 批量修改百度售卖房型(包括单日开关)
export function upBaiduRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upBadiduRp", param)
}
//单个售卖房型json 批量修改萝卜售卖房型(包括单日开关)
export function upLuoboRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upLuoboRp", param)
}
//单个售卖房型json 批量修改天下房仓售卖房型(包括单日开关)
export function upFctxRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upFctxRp", param)
}
//单个售卖房型json 批量修改携程、代理通售卖房型(包括单日开关)
export function upDltRp_api(param) {
     return req.postlg("/otaHotel/roomPrice/upDltRp", param)
}
//单个酒店批量修改房价
export function batchUpRoomVestPrice_api(param) {
     return req.postlg("/otaHotel/roomPrice/batchUpRoomVestPrice", param)
}
//获取单个酒店所有售卖房型的马甲
export function hotelRoomVestHorse_api(param) {
     return req.get("/otaHotel/roomPrice/getHotelRoomVestHorse", param)
}
//获取单个酒店所有售卖房型的马甲
export function hotelRoomVestTree_api(param) {
     return req.get("/otaHotel/roomPrice/getHotelRoomVestTree", param)
}
//单个酒店批量修改房态
export function batchUpRoomVestStatus_api(param) {
     return req.postlg("/otaHotel/roomPrice/batchUpRoomVestStatus", param)
}
//设置二次拦截
export function setInterceptPrice_api(param) {
     return req.postlg("/otaHotel/roomPrice/setInterceptPrice", param)
}
//取消二次拦截
export function cancelInterceptPrice_api(param) {
     return req.postlg("/otaHotel/roomPrice/setNotInterceptPrice", param)
}

//批量修改/售卖房型静态基础数据(根据平台区别
export function batchSetRoomVestBase_api(param) {
     return req.postlg("/otaHotel/room/batchSetRoomVestBase", param)
}
//批量修改/售卖房型静态数据(根据平台区别)
export function batchSetRoomVest_api(param) {
     return req.postlg("/otaHotel/room/batchSetRoomVest", param)
}
//推送，重新推送
export function sendRoomInfo_api(param) {
     return req.get("/otaHotel/roomVest/sendRoomInfo", param)
}
//查看匹配状态
export function checkRoomVestMapping_api(param) {
     return req.postlg("/otaHotel/room/checkRoomVestMapping", param)
}
//获取房型日志
export function roomLogList_api(param) {
     return req.get("/otaHotel/operation-log-info/list", param)
}
//下线售卖房型
export function delRoom_api(param) {
     return req.get("/otaHotel/roomVest/upDow", param)
}
//删除售卖房型
export function realDelRoom_api(param) {
     return req.get("/otaHotel/roomVest/del", param)
}
//删除物理房型
export function delSellRoom_api(param) {
     return req.get("/otaHotel/room-type-vest/del", param)
}
//房型管理》获取酒店匹配成功的马甲和已经添加了的物理房型
export function getMatchingVestAndRoomTypeInfo_api(param) {
     return req.get("/otaHotel/room-type/getMatchingVestAndRoomTypeInfo", param)
}
//房型管理》获取酒店匹配成功的马甲和已经添加了的物理房型
export function sendRoomType_api(param) {
     return req.get("/otaHotel/room-type-vest/sendRoomType", param)
}
//房型管理》获取物理房型匹配成功的马甲和已经添加了的售卖房型(酒店指定物理房型)
export function getMatchingVestAndRoomInfoById_api(param) {
     return req.get("/otaHotel/room-type/getMatchingVestAndRoomInfoById", param)
}
//重新发布售卖房型到马甲
export function sendRoom_api(param) {
     return req.get("/otaHotel/room-type-vest/sendRoom", param)
}
//删除匹配失败的马甲匹配记录
export function deleteMatchingFailVest_api(param) {
     return req.get("/otaHotel/hotelSonVest/deleteMatchingFailVest", param)
}
//删除关联
export function delAssociatedRoom_api(param) {
     return req.get("/otaHotel/room/delRoom1", param)
}

//将匹配成功的房型改成匹配中
export function changeIsMatching_api(param) {
     return req.get("/otaHotel/room/updateRoomVestStatus", param)
}

//设置艺龙酒店30分钟取消
export function set30MinuteCancel_api(param) {
     return req.get("/product/hotel/set30MinuteCancel", param)
}

//去哪儿礼包列表giftbagList_api
export function giftbagList_api(param) {
     return req.get("/otaHotel/promotion/getPromotionList", param)
}

//新增修改去哪儿礼包addPromotion_api
export function addPromotion_api(param) {
     return req.postlg("/otaHotel/promotion/addPromotion", param)
}

//删除去哪儿礼包
export function delGiftbag_api(param) {
     return req.get("/otaHotel/promotion/delPromotion", param)
}
//去哪儿礼包发布时候的房型列表
export function giftRoomList_api(param) {
     return req.get("/otaHotel/promotion/getRoomList", param)
}

//萝卜礼包列表
export function giftbagLuoboList_api(param) {
     return req.get("/otaHotel/promotion-luobo/getPromotionList", param)
}

//新增修改萝卜礼包addLuoboPromotion_api
export function addLuoboPromotion_api(param) {
     return req.postlg("/otaHotel/promotion-luobo/addPromotion", param)
}

//删除萝卜礼包delLuoboGiftbag_api
export function delLuoboGiftbag_api(param) {
     return req.get("/otaHotel/promotion-luobo/delPromotion", param)
}




//发布礼包 
export function sendPromotionFxs_api(param) {
     return req.get("/otaHotel/promotion/sendPromotionFxs", param)
}

//修改物理房型名称
export function updateRoomTypeName_api(param) {
     return req.get("/otaHotel/room-type/updateRoomTypeName", param)
}

//获取根据艺龙房型可以新增的物理房型
export function getRoomTypeList_api(param) {
     return req.get("/otaHotel/room-type/getRoomTypeList", param)
}
//获取根据艺龙房型可以新增的物理房型2.0 自己输入的艺龙母ID
export function getRoomTypeListbyelongId_api(param) {
     return req.get("/otaHotel/room-type/getRoomTypeListElong", param)
}

//根据艺龙房型新增物理房型
export function addRoomTypeUser_api(param) {
     return req.get("/otaHotel/room-type/addRoomTypeUser", param)
}

//一键同步获取马甲
export function getSyncVestInfo_api(param) {
     return req.get("/otaHotel/room-type/getVestInfo", param)
}
//一键同步物理房型售卖房型
export function syncRoomType_api(param) {
     return req.get("/otaHotel/room-type/syncRoomType", param)
}

//艺龙推荐匹配
export function yilongPush_api(param) {
     return req.get("/otaHotel/room/yilongPush", param)
}

//修改房间名称roomId
export function editRoomName_api(param) {
     return req.get("/otaHotel/room/updateRoomName", param)
}
//getLuoboPersonList_api   获取萝卜人员列表
export function getLuoboPersonList_api(param) {
     return req.get("/otaHotel/room-type/getUserList", param)
}

//setHotelSaleCommission_api  设置酒店销售佣金
export function setHotelSaleCommission_api(param) {
     return req.get("/otaHotel/room-type/setHotelSaleCommission", param)
}







